<template>
  <div class="server-layout">
    <h2>共享平台注册经营者合作协议书</h2>
    <div class="server-header"><span class="bold">甲 方：</span>{{name}}</div>
    <div class="server-header"><span class="bold">注册地址：</span>{{address}}</div>
    <div class="server-header">
      <span class="bold">乙 方：
      </span>身份证号：</div>
    <div class="tips">提示条款</div>
    <p class="intext">继续注册成为平台注册经营者前，请先阅读本协议提示条款：</p>
    <p class="intext">欢迎乙方与甲方【{{name}}】共同签署本《合作协议书》（下称“本协议”）成为本平台的注册经营者，以本平台为业务匹配渠道，个人从事生产经营活动，并取得相应的收益。
    </p>
    <p class="intext">【审慎阅读】为维护乙方的自身权益，在乙方点击同意本协议之前，请认真阅读本协议，务必审慎阅读、充分理解各条款内容。
    </p>
    <p class="intext">【签约】当乙方按照提示填写乙方的信息且完成全部申请后，即表示乙方已充分阅读、理解并接受本协议的全部内容，并与甲方达成一致意见，成为甲方的合作人员，此后乙方不得以未阅读/不理解本协议内容或类似言辞做任何形式的抗辩。阅读本协议的过程中，如果乙方不理解相关内容应及时联系我公司，如乙方不同意本协议或其中任何条款约定，请乙方立即停止任何的申请/注册程序。
    </p>
    <p class="intext">【合作关系】乙方与甲方通过本协议建立平等的业务承揽合作关系，适用《合同法》、《民法总则》和其他民事法律，不适用《劳动法》、《劳动合同法》。
    </p>
    <p class="intext">【声明】乙方知悉并向甲方承诺将合法合规的通过甲方平台以个体经营形式与甲方进行合作，且与发包方不存在劳动关系等禁止性特殊关系。乙方知悉且认可：甲方应当且仅应当对确由甲方承接并转包、分包给乙方来最终完成的任务负责，其具体以“发包商向甲方提供的包含乙方当期所完成的任务及其验收确认情况、费用结算标准、收款账户信息等数据的结算清单，以及发包商向甲方发出的可以给乙方结清经营所得的结算指令，并向甲方足额支付当期总服务费”的任务为准。
    </p>
    <p class="intext">【防范虚开发票等风险的提示】根据法律法规及司法解释的规定，开具发票须有真实业务基础，违法开具发票除承担缴纳税款、滞纳金、罚款等行政责任，还可能被追究刑事责任。构成虚开发票犯罪的，单位应会被处以罚金。对于个人而言，虚开增值税专用发票税额5-50万可判处有期徒刑3年以下，50-250万刑期为3-10年，250万以上刑期为10年以上直至无期徒刑，虚开普通发票100份以上或者虚开金额累计在40万元以上的刑期为2年以下，情节特别严重的刑期为2年以上7年以下；此外，还有罚金不等。因此，双方均应规范经营、合法展业，并独立对自己的行为负责，如给对方造成损失的，应当赔偿。    </p>


    <div class="tips">协议条款</div>
    <p class="intext">根据国家法律、法规和有关规定，双方按照平等自愿、诚实信用的原则，就乙方通过甲方运营平台承揽发包商（即在甲方所运营的平台上注册的不特定单位客户，后文简称为“发包商”）提供的业务，从事生产经营活动等事宜，特订立本服务合作协议。</p>
    <p class="intext bold">第一条 合作内容</p>
    <p class="intext">1.1 因甲方所运营平台上的发包商有寻求个人经营者承担一定工作任务的需求，乙方自愿以平台为业务渠道从事生产经营活动，为更好地规范和履行各方的权利、义务，甲乙双方签订本协议，以供双方严格遵守。为保证乙方与发包商的权利义务，甲方特此向乙方宣贯平台上的业务规则。关于各发包商对注册经营者的要求，包括应履行的活动条件、活动内容、活动注意事项等均在各发包商的任务发布页面有所体现，乙方应当予以遵守。</p>
    <p class="intext">1.2 甲方将以总包的名义承接发包商的相关灵活用工业务后，再转包给乙方，但甲方对其未参与的其他业务不承担责任，如乙方直接向发包商提供的服务、乙方通过其他平台或途径向发包商承接或提供的服务等，即甲方应当且仅应当对确由甲方承接并转包、分包给乙方来最终完成的任务（具体以“乙方与甲方进行注册签约，且乙方身份、银行卡等信息经甲方验证后，并且甲方收到发包商提供的包含乙方当期所完成的任务及其验收确认情况、费用结算标准、收款账户信息等数据的结算清单，以及发包商向甲方发出的可以给乙方结清经营所得的结算指令，并向甲方足额支付当期总服务费所对应的业务为准；下同，不再赘述）承担责任；对确认由甲方承接并转包、分包给乙方来最终完成的任务，乙方应按照甲方及各发包商要求的标准接收、完成并交付任务，履行生产经营活动过程中的相关义务并享有相关权利，严格遵循各发包商制定的任务政策或规则。如乙方违反前述规定，相关责任由乙方自行承担。</p>
    <p class="intext bold">第二条 协议效力</p>
    <p class="intext">2.1 本协议自乙方完成申请、注册并最终点击签署本协议电子版 或 在电子协议上签字确认 或 在本协议纸质版上签字确认 后立即生效。</p>
    <p class="intext">2.2 本协议为不定期合同，除法律另有规定或双方另有约定外，本协议自签署之日起长期有效。如乙方要求终止本协议并注销对应平台账号，或乙方长期未登录甲方平台并承接任务的，本协议可以随时终止。
    </p>
    <p class="intext bold">第三条 甲方的权利和义务</p>
    <p class="intext">3.1 甲方接受发包商的委托，就其所需发包的任务寻求、匹配注册经营者（即乙方）为其完成相应任务，并按乙方有效工作成果（具体以“由甲方承接并转包、分包给乙方来最终完成，并经发包商验收的业务”为准）向乙方结算承揽任务经营所得（以下称“经营所得”）。</p>
    <p class="intext">3.2 甲方按发包商提供的注册经营者经营所得计费标准和支付方式，根据发包商确认的金额向乙方结算经营所得，并承诺按时支付。如乙方在工作成果、标准和费用金额上有异议，可以向发包商提出，甲方将根据发包商的最终意见结算和支付费用。
    </p>
    <p class="intext">乙方确认知晓：就由甲方承接后转包、分包给乙方来最终完成的任务，乙方的经营所得应由甲方按约定结算并完成支付；如甲方收到发包商提供的包含乙方当期所完成的任务及其验收确认情况、费用结算标准、收款账户信息等数据的结算清单，以及可以给乙方结清其当期经营所得的结算指令，并足额收到当期总服务费后，但甲方未及时足额向乙方支付其对应款项的，相关责任由甲方承担，乙方不应向发包商主张任何费用。 反之，如甲方未收到发包商的服务/任务验收意见，或未收到发包商提供的对应结算清单，或未收到发包商发出的向乙方结算的操作指令，或未收到发包商足额支付的总服务费的，则甲方没有义务向乙方支付任何款项，且甲方对此亦不承担责任。</p>
    <p class="intext">3.3 在向乙方支付经营所得前，甲方将根据税务机关的授权代征乙方在前述生产经营过程中产生的个人所得税等各项税款及行政收费（如适用），在收到发包商的付款、缴纳所有税费后，甲方保证乙方的税后经营所得款项及时到账。</p>
    <p class="intext">3.4 乙方根据本协议为平台上的发包商完成任务的，系前述任务的实际承揽人，该任务并不导致乙方与发包商或与甲方之间构成任何劳动、劳务或雇佣关系，乙方应对其承揽行为负责。
    </p>
    <p class="intext">3.5 在乙方完成任务的过程中，应当自行保障人身和财产安全，并不得侵害第三人的人身、财产或其他权利，如出现问题由乙方自行承担责任。若因乙方在完成任务过程中给发包商或其他第三方造成损失，使甲方或发包商承担责任的，甲方或发包商有权向乙方追偿，并从乙方应得的税后经营所得中扣除，尚有不足的，甲方或发包商有权继续向乙方追偿。
    </p>
    <p class="intext">3.6 甲方承诺对乙方所披露的个人隐私信息进行保密，但为履行本协议或有关部门要求而披露的除外。
    </p>
    <p class="intext">3.7 甲方发现乙方违反本协议4.6条款之规定的，有权中止本协议，并将乙方的违法行为自发现之时24小时之内向甲方主管税务机关报告。乙方应自行承担由此产生的税务相关责任，包括但不限于根据税务机关要求缴纳税款、滞纳金、罚款等。
    </p>
    <p class="intext">3.8 甲方有权对乙方提供的信息、完成发包商安排的任务情况进行调查核实、电话回访，乙方应全力配合。
    </p>
    <p class="intext bold">第四条 乙方的权利和义务</p>
    <p class="intext">4.1 如发包商要求乙方完成违反法律法规的任务的，乙方有权且应当拒绝。
    </p>
    <p class="intext">4.2 乙方注册和签约时需提供必要的签约信息【包括乙方的姓名（或名 称）、身份证号（或登记注册号）、车牌号码、手机号、账号等信息）】，如各单位需要其他具体信息的，请乙方按照发包商的要求进行提供。乙方承诺并保证乙方所提供的信息真实、准确，如甲方或发包商发现乙方伪造或提供虚假信息的，一经核实，甲方有权单方面立即解除本协议，冻结或扣除乙方尚未结算的税后经营所得作为赔偿。如乙方的信息变更（例如，不再符合注册经营者身份要求时等）应当及时通知甲方，否则与此有关的全部法律责任由乙方负责。
    </p>
    <p class="intext">4.3 乙方同意甲方及其发包商收集乙方的个人信息，包括但不限于乙方的姓名、公民身份号码、收款账户信息、联系电话、住址、接单数量及费用等履行本协议项下的义务所必须的信息。如有超出上述部分的，甲方将以平台通知等其他方式告知乙方并取得乙方同意和授权。
    </p>
    <p class="intext">4.4 就由甲方承接并转包、分包给乙方来最终完成的任务，乙方作为相关任务的最终实际承揽人，应当独立承担相应的责任，甲方没有为乙方购买任何保险的法律义务，且从未承诺为乙方购买任何形式的保险，且对相关保险费用不负有任何义务。乙方应当根据实际业务需要完全自主决定是否购买、购买合作保险并自行负担购买商业保险等保险费用，以有效降低和分散服务过程中可能发生的经营风险，但乙方投保、出险及理赔事宜均与甲方、发包商无关。
    </p>
    <p class="intext">4.5 乙方应自备提供/完成任务所需要的经营设备、设施及工具，自行承担必要费用和成本，并自行承担经营风险，即乙方知晓并同意：甲方对乙方完成任务过程中所引起的任何纠纷和责任（包括但不限于导致乙方自身或第三人出现人身损害或财产损失或其他事故、侵权、违约等所有的纠纷、责任和风险）以及乙方任务质量问题不承担任何责任，乙方及其相关责任主体自行独立解决，同时甲方对此不负有任何先行垫付的义务和责任。不论因何种理由导致甲方因乙方行为对外承担了任何责任或费用的，甲方均有权向乙方追偿，如甲方先行垫付的，有权要求乙方全额赔偿。
    </p>
    <p class="intext bold">4.6 乙方承诺与发包商不具有下列关系的任何一种：
    </p>
    <p class="intext bold">（1）与发包商具有劳动、劳务或雇佣关系，或其他类似的劳动人事法律关系；
    </p>
    <p class="intext bold">（2）与发包商的关联企业具有劳动、劳务或雇佣关系，或其他类似的劳动人事法律关系；
    </p>
    <p class="intext bold">（3）为发包商及其关联企业的法定代表人、董事、监事、股东。
乙方应当自行注意和规范与发包商及其关联方的交易方式及法律关系，以避免形成事实上的或易被误解为劳动、劳务等上述特殊法律关系的外观；如乙方拟与发包商及其关联方建立或已经存在上述特殊关系或与本协议合同目的冲突的其他关系的，应当书面告知甲方并停止继续通过甲方平台与发包商或其关联方进行合作，否则相关责任应由乙方自行承担。
前述发包商的关联企业指，该企业与发包商相互间直接或间接持有其中一方的股权总和达到25%或以上的；或者与发包商直接或间接同为第三者所拥有或控制股权达到25%或以上的；或者对发包商生产经营、交易具有实际控制的与其他利益相关联的关系（包括但不限于婚姻、近亲属关系）。

    </p>
    <p class="intext bold">4.7 乙方应保证完成甲方以及发包商所安排工作任务，并与甲方保持必要的沟通联络并应当且仅应当遵守的发包商的工作守则等相关管理规定，仅限于服务质量要求、正常业务管理和操作规章，不包括企业正式员工所需遵守的考勤管理、休息休假、离岗报备、财务报销等劳动制度，以及调动、晋升、降职等具有“人身依附性”和“组织隶属性”的用工管理规定；且乙方应当知晓并保证：与企业及其关联方不得存在、建立或形成事实上的劳动关系、劳务关系、雇佣关系、劳务派遣、人力资源外包、关联方外派/委派等上述特殊法律关系。
    </p>
    <p class="intext">4.8 乙方应按时尽责地完成符合甲方以及发包商要求的任务，在此过程中不得有侵犯人身权、肖像权、隐私权、名誉权、财产权、知识产权、商业秘密或其他合法权益以及其他违反国家法律法规、国家政策或有悖于公序良俗的行为（包括但不限于传销、赌博、暴力、淫秽、恐怖行为、洗钱、逃税、虚开发票等）。否则，乙方应当自行承担相关法律责任。
    </p>
    <p class="intext">4.9 协议期内，乙方应在甲方以及发包商许可范围内使用发包商的商业信息；协议期内以及协议终止后，乙方均有义务对双方合作的有关协议及合作过程中知悉的甲方、发包商及他人的相关信息（包括但不限于乙方与甲方签订的本协议，及服务过程中了解到的发包商产品信息、相关服务政策、价格标准、销售数量、技术秘密及其他情况）予以保密，未经书面许可，乙方不得以任何方式向其它方泄漏、给予或转让前述保密信息。否则，乙方需自行负责赔偿因此导致甲方及发包商的全部损失。
    </p>
    <p class="intext">4.10 除本协议事项外，乙方不得以甲方或发包商的名义开展任何与完成约定的任务内容无关的业务或活动。
    </p>
    <p class="intext">4.11 乙方为发包商完成约定的任务时，发包商可能会要求乙方签署、签署电子协议《发包商与注册经营者的协议》或在纸质版《发包商与注册经营者的协议》，乙方可以视情况自行决定是否签署。乙方应自行认真阅读并审慎决定是否签署《发包商与注册经营者的协议》，并明确知悉签署该协议将对乙方产生的法律效力。甲乙双方均知晓并同意，就乙方所提供的服务，如确有必要的，乙方与发包商另行签署的协议，仅作为其双方对服务内容、标准、交付验收、费用核算等方面的确认，不构成对本协议的变更，且甲方对上述乙方与发包商另行签署的其他协议不承担责任。
    </p>
    <p class="intext">4.12 甲方及本平台默认，乙方于同一期间内，只在本平台注册为注册经营者并接受相应的经营所得收入支付和个人所得税等各项税费的代缴服务，若乙方在一个纳税年度内的收入情况依法应汇算清缴，乙方应依法自行完成汇算清缴，并按规定补缴税款。
    </p>
    <p class="intext">4.13 乙方不得单独或与发包商合谋利用甲方从事或达成包括但不限于传销、赌博、暴力、淫秽、恐怖行为、洗钱、偷税、漏税、逃税、平台虚开发票等违法行为或目的，或不得利用不正当手段在甲方向乙方进行结算将部分或全部资金回流至发包商或其关联方；否则，乙方应当自行承担相关责任。
    </p>
    <p class="intext">4.14 除遵守上述规定外，乙方还应当保证所提供的服务成果以及服务过程均不得违反中华人民共和国有关法律法规的规定，其包括但不限于： 
    </p>
    <p class="intext bold">1）	反对宪法所确定的基本原则的；
    </p>
    <p class="intext bold">2）	危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的； 
    </p>
    <p class="intext bold">3）	损害国家荣誉和利益的；  
    </p>
    <p class="intext bold">4）	煽动民族仇恨、民族歧视、破坏民族团结的；  
    </p>
    <p class="intext bold">5）	破坏国家宗教政策，宣扬邪教和封建迷信的；  
    </p>
    <p class="intext bold">6）	散布谣言，扰乱社会秩序，破坏社会稳定的；  
    </p>
    <p class="intext bold">7）	散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；  
    </p>
    <p class="intext bold">8）	侮辱或者诽谤他人（包括商业诽谤），侵害他人合法权利的；  
    </p>
    <p class="intext bold">9）	煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的； 
    </p>
    <p class="intext bold">10）	以非法民间组织名义活动的； 
    </p>
    <p class="intext bold">11）	含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容； 
    </p>
    <p class="intext bold">12）	含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的； 
    </p>
    <p class="intext bold">13）	如乙方/本人有任何违法违规或违反本协议约定的行为，应承担对甲方以及发包商的相关法律责任并赔偿损失。 
    </p>
    <p class="intext bold">第五条 税后经营所得收入的支付</p>
    <p class="intext">5.1 就确由甲方承接并转包、分包给乙方来最终完成的任务，甲方应当根据发包商的运营要求，按约定向乙方支付税后经营所得，具体由甲方根据乙方在发包商的实际服务情况、用户评价以及发包商的结算清单等资料确定。由于乙方所完成任务的质量、发包商运营情况等的不同，该收入金额可能会呈现浮动，乙方知晓并认可该等浮动为正常情况。甲方将以人民币形式向乙方支付税后经营所得，乙方应缴纳的个人所得税及其他税费由甲方负责代征代办。上述代征、代为扣缴等约定以及本协议项下相关约定，均不应视为甲方对乙方任何生产经营所得负有申报、汇算清缴或者汇总申报、代为承担税费之责任，即甲方应当且仅应当就乙方合法合规的为发包商提供本协议项下确由甲方承接并转包、分包给乙方来最终完成的任务而取得相应经营所得收入代为进行上述涉税业务，并对其他任何情形（包括但不限于非确认由甲方承接并转包、分包给乙方来最终完成的任务所应取得的款项，乙方与发包商弄虚作假、合谋损害甲方利益或名誉，或有其他违约违法违规情形）均不承担任何义务和责任。
    </p>
    <p class="intext">5.2 乙方应向甲方提供乙方实名登记的银行账户或支付宝账户，具体账户信息以乙方向发包商提供的信息为准。</p>
    <p class="intext">5.3 当且仅当甲方收到发包商提供的结算清单和结算指令，并足额收到发包商支付的相关款项后，甲方才有义务向乙方支付其税后经营所得。税后经营所得发放至乙方提供的收款账户，因乙方提供的收款账户不实造成的一切损失由乙方自行承担。乙方账号如变更或发生不可用等情况，乙方应及时通过发包商进行变更操作，否则，由此造成的一切损失由乙方自行负责。
    </p>
    <p class="intext bold">第六条 违约责任</p>
    <p class="intext">6.1 双方应按本协议约定履行，如有违反，守约方有权要求对方及时改正；造成对方损失的，守约方有权要求违约方赔偿。如乙方因违约行为给甲方或发包商造成损失的，甲方或发包商有权直接从乙方的应得税后经营所得中扣除相应的款项作为赔偿。
    </p>
    <p class="intext">6.2 在协议因违约而终止情况下，守约方可以要求违约方承担违约责任并赔偿损失，赔偿损失的范围应包括守约方因违约方违约所受到的全部经济损失，守约方还可保留进一步追究的权利。</p>
    <p class="intext">6.3因不可抗力造成损失的，彼此不负赔偿责任，但一方应及时将发生不可抗力有关情况通知另一方，并尽最大努力进行补救。本协议所称不可抗力是指不能预见、不能克服并不能避免且对一方当事人造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、火灾和风暴等，社会事件如战争、动乱、政府行为、黑客事件、大范围电信事故等，以及政府行为、政策法律变更等。
    </p>
    <p class="intext bold">第七条 协议的终止</p>
    <p class="intext">7.1 本协议在发生下列情形之一时，本协议自动终止：</p>
    <p class="intext">（1）本协议到期，双方未决定继续合作的；</p>
    <p class="intext">（2）乙方主动提出终止任务，且已经发包商同意的；</p>
    <p class="intext">（3）发包商终止或变更业务模式而不需要乙方继续履行本协议的；</p>
    <p class="intext">（4）发包商认为乙方不适合继续承揽任务的；</p>
    <p class="intext">（5）法律法规或政策变更，不再允许双方继续以此模式合作的。</p>
    <p class="intext">（6）发包商与甲方终止合作且乙方并未通过甲方平台承接其他发包商的相关业务的；</p>

    <p class="intext">7.2 发生以下情形时之一的，甲方可以<span class="bold">终止本协议：</span></p>
    <p class="intext">（1）乙方被证实符合本协议<span class="bold">第4.6条</span>的情况而不适宜承揽本平台上的任务的；</p>
    <p class="intext">（2）乙方的能力、态度或完成任务的成果如未能达到甲方或发包商的要求，甲方在自行发现乙方的瑕疵或收到发包商的通知，且经甲方核实确认乙方所完成的任务确有瑕疵时，甲方将对乙方给予警告并责令乙方改正；如乙方限期内未能予以改正或此后如仍发生类似情况的，甲方可单方面解除本协议；
    </p>
    <p class="intext">（3）乙方因违反发包商的规定或相应的操作规范，导致发包商有充分的理由认为乙方不适合继续承揽发包商发包的任务的；
    </p>
    <p class="intext">（4）乙方提供虚假信息，从事违法活动，或侵害甲方、发包商、他人的人身、财产或其他权益，情节恶劣或造成严重损失的。
    </p>
    <p class="intext">（5）乙方与发包商弄虚作假、合谋损害甲方利益或名誉，或有其他违约违法违规情形的。
    </p>
    <p class="intext">7.3 一方违反协议约定，且违约方在非违约方书面通知后 15 日内仍未予改正的，守约方可以单方终止协议。
    </p>
    <p class="intext">7.4 本协议的终止不影响已经产生的权利义务关系，也不解除各方的保密义务。
    </p>
    <p class="intext bold">第八条 不可撤销委托授权</p>
    <p class="intext">8.1 涉税事务授权委托</p>
    <p class="intext">（1）合作期限内，乙方不可撤销地授权并委托甲方办理本协议交易项下的涉税事务，使得甲方能够代理乙方办理临时税务登记、开票、申报缴税等相关涉税事宜。
    </p>
    <p class="intext">（2）乙方确认甲方根据本协议授权，提交的相关申报资料和信息真实、完整、准确和符合有关法律法规。
    </p>
    <p class="intext">8.2 信息查询授权委托
    </p>
    <p class="intext">（1）合作期限内，乙方授权甲方向中国人民银行、依法设立的数据服务机构、信息查询及信息验证服务机构、政府部门及其他依法设立存续的第三方机构等无次数限制地收集、查询、验证，同意递交本人相关信息（包括但不限于银行实名信息、通信实名信息），进行验证并获得验证结果。
    </p>
    <p class="intext">（2）乙方同意为避免重复授权之不便，本授权表明该等合法存续的第三方机构在使用或向有关机构提供本人信息时，可以依据本授权径行使用或向源数据机构提供本人相关信息，而无需再逐一向本人另行获取授权。</p>
    <p class="intext">8.3 特别提示：为了维护乙方的合法权益，请乙方在充分理解本授权书的全部内容后签署本授权书。本公司将依法对信息进行保密，履行信息安全保障义务。若乙方不接受本授权书任何条款，请乙方立即停止授权。
    </p>
    <p class="intext">8.4 授权人声明：本授权书经接受后立即生效，且效力具有独立性，不因相关业务合同或条款无效或s被撤销而无效或失效，本授权一经做出，便不可撤销。以上授权内容，本人已经充分理解并同意，一经本人点击确认或勾选时即视为签署。本人自愿做出以上授权，并愿意承担由此所产生的一切法律后果。
    </p>
    <p class="intext bold">第九条 特别提示
    </p>
    <p class="intext">9.1为防范发票虚开风险，我们在为您提供优质服务的同时，特别提示如下:
    </p>
    <p class="intext">9.1.1 您应当对本次提供的服务及其开票信息的真实性、完整性和准确性承担法律责任。
    </p>
    <p class="intext">9.1.2 虚开发票的定义
      《中华人民共和国发票管理办法》第二十二条规定:
      任何单位和个人不得有下列虚开发票行为:
      (一)为他人、为自己开具与实际经营业务情况不符的发票;
      (二)让他人为自己开具与实际经营业务情况不符的发票;
      (三)介绍他人开具与实际经营业务情况不符的发票。

    </p>
    <p class="intext">9.2 虚开发票的后果
《中华人民共和国刑法》相关规定:
第二百零五条 【虚开增值税专用发票、用于骗取出口退税、抵扣税款发票罪】虚开增值税专用发票或者虚开用于骗取出口退税、抵扣税款的其他发票的，处三年以下有期徒刑或者拘役，并处二万元以上二十万元以下罚金;虚开的税款数额较大或者有其他严重情节的，处三年以上十年以下有期徒刑，并处五万元以上五十万元以下罚金;虚开的税款数额巨大或者有其他特别严重情节的，处十年以上有期徒刑或者无期徒刑，并处五万元以上五十万元以下罚金或者没收财产。
单位犯本条规定之罪的，对单位判处罚金，并对其直接负责的主管人员和其他直接责任人员，处三年以下有期徒刑或者拘役;虚开的税款数额较大或者有其他严重情节的，处三年以上十年以下有期徒刑;虚开的税款数额巨大或者有其他特别严重情节的，处十年以上有期徒刑或者无期徒刑。
虚开增值税专用发票或者虚开用于骗取出口退税、抵扣税款的其他发票，是指有为他人虚开、为自己虚开、让他人为自己虚开、介绍他人虚开行为之一的。
第二百零五条之一 【虚开发票罪】虚开本法第二百零五条规定以外的其他发票，情节严重的，处二年以下有期徒刑、拘役或者管制，并处罚金;情节特别严重的，处二年以上七年以下有期徒刑，并处罚金。
单位犯前款罪的，对单位判处罚金，并对其直接负责的主管人员和其他直接责任人员，依照前款的规定处罚。
请您务必认真阅读并充分理解且完全认可上述《特别提示》，知悉相关法律规定，愿意接受其约束，并愿意承担相关法律责任后，再点击确认或勾选同意（或在电子协议上签字确认或在本协议纸质版上签字确认）。如您不同意上述全部或部分条款的约定，请您务必立即停止任何的申请/注册程序，并与平台方沟通咨询确认或咨询相关法律专业人士后再另行决定。
本《特别提示》自您通过平台系统勾选并点击“同意/确认/提交”等相关按钮或在电子协议上签字确认或在本协议纸质版上签字确认或在完成相关注册手续之日起生效，即对您以及平台方均具有约束力。

    </p>
    <p class="intext bold">第十条 其他约定
    </p>
    <p class="intext">10.1 因履行本协议发生的纠纷，双方应友好协商解决，协商不成的，提请北京仲裁委员会仲裁，仲裁裁决为一裁终局，对双方具有约束力。</p>
    <p class="intext">10.2 甲方有权根据业务变化情况修改本协议条款。</p>


    <p class="intext bold">权利义务及其风险告知书
    </p>
    <p class="intext">尊敬的注册经营者：
您好！非常感谢您对我平台的认可，并申请成为我平台的注册经营者！就此，特向您郑重提示如下重要内容：
1.	【注册经营者】我平台所称“注册经营者”，又称为“自由职业者”“灵工人员”“灵活就业人员”“个体经营者”等，具体是指通过自我雇佣、自我管理、自担成本、自负盈亏，以临时性、弹性工作、非全日制等方式，以获取经营所得（即任务酬劳）为目的，通过我平台合法合规的从事生产经营活动、商业活动的个人或临时税务登记主体，并且与我平台及接受您所提供服务的发包商不存在劳动关系、劳务关系、投资、借贷等禁止性特殊关系，同时并非军人、公职人员等国家法律法规和纪律规定禁止、限制从事兼职或经商的人员。
2.	【合作关系】我平台的业务模式是我平台将以总包的名义承接发包商的相关灵活用工业务后，再以转包、分包的方式交给您实际完成。因此，所以您与我平台建立的是平等民事主体间的业务承揽合作关系，适用《中华人民共和国民法典》及其相关民事法律，不适用《劳动法》《劳动合同法》及其他劳动法律法规。
3.	【经营所得（即任务酬劳）】我平台将根据您当期实际完成并交付验收合格的任务情况，以人民币的形式向您支付税后经营所得，具体金额以发包商提供结算清单及结算指令为准；同时，我平台应当且仅应当在收到发包商提供的结算清单以及可以给您结清当期经营所得的结算指令，并足额收到当期总服务费后，我平台才有义务及时足额向您支付对应款项；反之，如未收到发包商的服务/任务验收意见，或未收到发包商提供的对应结算清单，或未收到发包商发出的向您结算的操作指令，或未收到发包商足额支付的总服务费的，则我平台将没有义务和权限向您支付任何款项。
4.	【涉税事务】就上述经营所得，您所应缴纳的个人所得税将由我平台负责代征代办。但上述代征代办等约定不应视为我平台对您所有收入都负有申报、汇算清缴或者汇总申报、代为办理、代为承担之责任，即我平台应当且仅应当就您合法合规的为发包商提供确由我平台承接并转包、分包给您来最终完成的任务而取得相应经营所得收入代为申报、代为征收，并对其他任何情形（包括但不限于非确认由我平台承接并转包、分包给您来最终完成的任务所应取得的款项，您与发包商弄虚作假、合谋损害我平台利益或名誉，或有其他违约违法违规情形）均不承担任何义务和责任。
5.	免责声明：
5.1	我平台应当且仅应当对确由我平台承接并转包、分包给您来完成的任务所对应的款项负责，其具体以“发包商向我平台提供的包含您当期所完成的任务及其验收确认情况、费用结算标准、收款账户信息等数据的结算清单，以及发包商向我平台发出的可以给您结清经营所得的结算指令，并已向我平台足额支付当期总服务费”的任务为准。
5.2	就确由我平台承接并转包、分包给您来最终完成的任务，您作为相关任务的实际承揽人，应当独立承担相应的责任，我平台没有为您购买任何保险的法律义务，且从未承诺为您购买任何形式的保险，且对相关保险费用不负有任何义务。您应当根据实际业务需要完全自主决定是否购买、购买合作保险并自行负担购买相关保险的保险费用，以有效降低和分散服务过程中可能发生的经营风险，但您投保、出险及理赔事宜均与我平台无关。本平台对您投保、出险及理赔等事宜均未做出任何承诺，亦不负有任何义务。
5.3	您应根据任务的具体需要，自备提供/完成任务所可能需要的经营设备、设施及工具和场所，自行组建相关团队和人员，自行承担全部费用和成本，并自行承担经营风险、服务风险和责任，即您知晓并同意：我平台对您完成任务过程中所引起的任何纠纷和责任（包括但不限于导致您自身或第三人出现人身损害或财产损失或其他事故、侵权、违约等所有的纠纷、责任和风险）以及您任务质量问题和风险不承担任何责任，您及其相关责任主体自行独立解决，同时我平台对此不负有任何责任或先行垫付的义务。不论因何种理由导致我平台因您行为对外承担了任何责任或费用的，我平台均有权向您追偿，如我平台先行垫付的，有权要求您全额清偿。
5.4	在完成任务的过程中，您应当自行保障人身和财产安全，并不得侵害第三人的人身、财产或其他权利，如出现任何问题均应由您自行承担责任。若因您在完成任务过程中给我平台、发包商或其他第三方造成损失，或由此导致我平台对外承担责任的，我平台有权向您追偿，并从您应得的税后经营所得中扣除，尚有不足的，我平台有权继续向您追偿。
5.5	在完成任务的过程中，您应当尊重发包商、客户的服务需求及业务流程和标准，但上其仅限于服务质量要求、正常业务管理和操作规章，不包括发包商正式员工所需遵守的考勤管理、休息休假、离岗报备、财务报销等劳动制度，以及调动、晋升、降职等具有“人身依附性”和“组织隶属性”的用工管理规定。同时，您不得与本平台、发包商及其关联方存在、建立或形成事实上的劳动关系、劳务关系等特殊关系。如有的，则您应立即停止使用我平台承接订单、交付服务成果，否则本平台有权拒绝支付任务酬劳，已经支付的有权追回。且相关责任由您及发包商自行承担。

请您务必认真阅读并充分理解且完全认可上述《权利义务及其风险告知书》，知悉相关法律规定，愿意遵守并接受其约束，并愿意承担其可能存在的风险及其相关法律责任后，再点击确认或勾选同意（或在本告知书电子版或纸质版上签字确认）。如您不同意上述全部或部分内容或不接受其全部或部分约束的，请您务必立即停止申请/注册程序、拒绝接单等所有操作，并与我平台进行沟通咨询确认后或咨询相关法律专业人士后再另行决定。
本《权利义务及其风险告知书》自您点击同意本协议或在电子协议上签字确认或在纸质版协议上签字确认之日起生效，即对您以及我平台均具有约束力，此后不得以未阅读/不理解本协议内容或类似言辞做任何形式的抗辩。

确认人（签字确认）：
日期：
</p>

    <br>
    <br>
    <br>
    <br>
<!--    <van-button class="agree-btn" type="info" @click="agreeded">-->
<!--      已阅读并同意</van-button>-->
    <br>
    <br>
  </div>
</template>
<script>

export default {
  name: 'server',
  data () {
    return {
      time: 9000,
      name: '',
      address: ''
    }
  },
  mounted () {
    this.name = this.$route.query.name
    this.address = this.$route.query.address
  },
  methods: {
    agreeded () {
      this.$router.push({
        name: 'userInfo',
        params: {
          id: true
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
  .server-layout{
    color: #333;
    padding: 50px 40px;
    text-align: left;
    h2{
      text-align: center;
      font-size: 38px;
    }
    .bold{
      font-weight: bold;
    }
    .tips{
      font-size: 30px;
      font-weight: bold;
      padding: 10px 0;
    }
    .server-header{
      font-size: 30px;
    }
    .intext{
      font-size: 14px;
    }
    .agree-btn{
      display: block;
      margin: 0 auto;
    }
  }

</style>
